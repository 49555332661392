import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "wording"
    }}><inlineCode parentName="h1">{`wording`}</inlineCode></h1>
    <p>{`This command line utility allows you to download a Google Sheet into multiples json files.`}</p>
    <p>{`This script will only work if you follow the following rules : `}</p>
    <ul>
      <li parentName="ul">{`Use the file "wording v2 template" (in Google Drive) as a template`}</li>
      <li parentName="ul">{`The first column should always contains unique keys`}</li>
      <li parentName="ul">{`The first row of a sheet should contains column headers (key, fr, en)`}</li>
      <li parentName="ul">{`You can use multiples tabs`}</li>
    </ul>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{}}>{`yarn install @redoute/wording
`}</code></pre>
    <p>{`You can now add the command to the scripts of your package.json  `}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{}}>{`redoute-wording -c ~/service-account.json -f oa2s03ddo4ujEijn4 -o ./wording/
`}</code></pre>
    <p>{`Where the 3 parameters are : `}</p>
    <pre><code parentName="pre" {...{}}>{`-c (or -config): the path to the service account key (json file) <sup>(1)</sup>
-f (or -file): The id of the google sheet file (available in the url)
-o (or -output): The folder where you want your files to be generated
`}</code></pre>
    <p>{`(1) more about service accounts `}<a parentName="p" {...{
        "href": "https://cloud.google.com/iam/docs/creating-managing-service-account-keys"
      }}>{`here`}</a></p>
    <p>{`Don't forget to share the document with the same email address used in your service account json file ("client_email").`}</p>
    <p>{`Now you can try this command and check in your output directory`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn run wording
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      